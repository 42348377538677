import React from 'react';
import { graphql } from 'gatsby';

import { useLocale } from '@app/hooks';
import { Breadcrumb, Layout } from '@app/components';

const NotFound: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'pages.404',
  };
  const { t } = useLocale();

  return (
    <Layout>
      <Breadcrumb
        items={[
          { path: '/', name: t('labels.home', SCOPE_OPTIONS) },
          { path: '/', name: t('labels.title', SCOPE_OPTIONS) },
        ]}
      />

      <section className="py-28 text-center">
        <div className="container mx-auto px-1.5">
          <h2 className="text-10xl font-bold text-gray-900 filter drop-shadow-lg">
            <span>404</span>
          </h2>
          <h3 className="text-4xl text-gray-400 my-14">{t('labels.description', SCOPE_OPTIONS)}</h3>
        </div>
      </section>
    </Layout>
  );
};

export default NotFound;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
